import axios from "axios";
import { useEffect } from "react";
import * as React from 'react';

import {Box, Typography, Grid, IconButton, MenuItem, Menu} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import Sidebar from "./sidebar";
import ChapterButton from "./ChapterButton";
import WithdrawPopUp from "./WithdrawPopUp";
import { useSelector } from 'react-redux';

const ChapterPage = ({menuOpen, isCustomBreakpoint}) => {
	//Set role to either "admin" or "president" to change the page
	const [role,setRole] = React.useState("");
    const [anchorElRequested, setAnchorElRequested] = React.useState(null);
    const [selectedRequestedRow, setSelectedRequestedRow] = React.useState(null);
    const [anchorElApproved, setAnchorElApproved] = React.useState(null);
    const [selectedApprovedRow, setSelectedApprovedRow] = React.useState(null);

	const openRequested = Boolean(anchorElRequested);
	const openApproved = Boolean(anchorElApproved);
    const [requestedChapters, setRequestedChapters] = React.useState([]);
    const [activeChapters, setActiveChapters] = React.useState([]);
	const [openPopUp, setOpenPopUp] = React.useState(false);
	const userRole = useSelector((state) => state.role);

	const imageStyle = {
		width: '100%',
		height: 'auto',
		display: 'block',
		objectFit: 'cover',
		aspectRatio: '1 / 1', // Ensures the image stays square
	};


    const handleClickRequested = (event, row) => {
		setAnchorElRequested(event.currentTarget);
		setSelectedRequestedRow(row);
	  };
    const handleCloseRequested = () => {
		setAnchorElRequested(null);
		setSelectedRequestedRow(null);
	};

	const handleApprove = async () => {
		try {
		  await axios.patch(`${process.env.REACT_APP_SERVER_URL}/admin/chapters/${selectedRequestedRow.chapterId}/approve`);
		  setRequestedChapters(requestedChapters.filter(chapter => chapter.chapterId !== selectedRequestedRow.chapterId));
		  setActiveChapters([...activeChapters, { ...selectedRequestedRow, status: 'activeChapter' }]);
		  console.log('Approve', selectedRequestedRow);
		} catch (error) {
		  console.error('Error approving request:', error);
		}
		handleCloseRequested();
	  };

	const handleDecline = async () => {
		try {
			await axios.delete(`${process.env.REACT_APP_SERVER_URL}/admin/chapters/${selectedRequestedRow.chapterId}/reject`);
			setRequestedChapters(requestedChapters.filter(chapter => chapter.chapterId !== selectedRequestedRow.chapterId));
			console.log('Decline', selectedRequestedRow);
		} catch (error) {
			console.error('Error declining request:', error);
		}
		handleCloseRequested();
	};

	const handleClickApproved = (event, row) => {
		setAnchorElApproved(event.currentTarget);
		setSelectedApprovedRow(row);
	};

	const handleCloseApproved = () => {
		setAnchorElApproved(null);
		setSelectedApprovedRow(null);
	};
	const handleNewChapter = (chapter) => {
		setRequestedChapters([...requestedChapters, chapter]);
	};
    const handleEditRequested = (updatedChapter) => {
		setRequestedChapters(requestedChapters.map(chapter => chapter.chapterId === updatedChapter.chapterId ? {...setRequestedChapters, ...updatedChapter} : chapter));
		handleCloseApproved();
	};
    const handleEditActive = (updatedChapter) => {
		setActiveChapters(activeChapters.map(chapter => chapter.chapterId === updatedChapter.chapterId ? {...activeChapters, ...updatedChapter} : chapter));
		handleCloseApproved();
	};
    const handleWithdraw = async (chapterId) => {
		try {
			const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/chapter/withdrawChapter/${chapterId}`, { withCredentials: true });
			setActiveChapters(activeChapters.filter(chapter => chapter.chapterId !== chapterId));

		} catch (err) {
			console.error(err);
		}
		handleCloseApproved();
	};

    useEffect(() => {
		const fetchData = async () => {
		  try {
			if (userRole === "hotelManager") {
				const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/chapter/hotel`, { withCredentials: true });
				console.log(response.data.chapter);
				const activeChapters = response.data.chapter
				setActiveChapters(activeChapters);

			}
			else{
			const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/chapter/getChapters`, { withCredentials: true });
			const {result, role} = response.data;
			const activeChapters = result.filter((chapter) => chapter.status === "activeChapter");
			const requestedChapter = result.filter((chapter) => chapter.status === "requestedChapter");
			setRole(role);
			console.log(role);
			setRequestedChapters(requestedChapter)
			setActiveChapters(activeChapters);
			}
		  } catch (error) {
			console.error('Error fetching data:', error);
		  }
		};

		fetchData();
	}, []);

	return (
		<Box>
			<Grid container>
				<Grid container>
					<Grid item xs={12} sx ={{padding: '1% 2%'}}>
						{role ==="chapterPresident" &&
						<Box width='100%' display='flex' justifyContent='flex-end'>
							<ChapterButton type="new" onSubmit={handleNewChapter}/>
						</Box>
						}

						<Box sx={{overflowY: 'auto'}}>
							{(role === 'chapterPresident'|| role === 'Admin') &&
							<Box className="requested-chapters">
								<Box sx={{paddingY: '1%',fontWeight: 500, fontSize: 18, color: "#343C6A"}}>
									Requested Chapters
								</Box>
								<Box display="flex" flexDirection="column" gap="1rem">
									{requestedChapters.map((chapter)=>(
										<Box key={chapter.chapterId} width="100%" minWidth="700px" maxWidth="1750px" display="flex" gap="1.5rem" paddingX="2rem" justifyContent="space-around" bgcolor="#FFFFFF"  sx={{ borderRadius: 4}}>
											<Box width="100%" maxWidth="200px" overflow="hidden" paddingY="5px">
											<img 
												style={imageStyle} 
												src={chapter.chapterPhoto !== "" ? chapter.chapterPhoto : "/images/login.png"} 
												alt="Chapter"
											/>
											
											</Box>
											<Box flexGrow={1} display="flex" flexDirection="column" alignContent="center" justifyContent="space-around">
												<Box>
													<Typography variant="h3"><strong>Name:</strong> {chapter.chapterName}</Typography>
													<Typography variant="h3"><strong>Address:</strong> {chapter.chapterAddress}</Typography>
												</Box>
												<Box>
													<Typography variant="h3"><strong>President:</strong> {chapter.chapterPresident}</Typography>
													<Typography variant="h3">{chapter.email}</Typography>
												</Box>
											</Box>
											{(role === 'Admin' || chapter.userStatus === 'president') &&										
											<Box display="flex" flexDirection="column" alignContent="center" justifyContent="space-around" >
												<IconButton onClick={(event) => handleClickRequested(event, chapter)} >
													<MoreVertIcon sx={{ fontSize: 60 }}/>
												</IconButton>
												<Menu
													anchorEl={anchorElRequested}
													open={openRequested}
													onClose={handleCloseRequested}
													anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
													transformOrigin={{ vertical: 'top', horizontal: 'right' }}
													sx={{width: '200px'}}
												>
													{role === "Admin" && (
														<>
															<MenuItem onClick={handleApprove}>Approve</MenuItem>
															<MenuItem onClick={handleDecline}>Decline</MenuItem>
														</>
													)}
													<ChapterButton type="edit" handleCloseApproved={handleCloseApproved} chapter={selectedRequestedRow} onEdit={handleEditRequested}/>
												</Menu>
												<IconButton disableFocusRipple disableRipple>
													<HistoryToggleOffIcon sx={{ fontSize: 40 }} />
												</IconButton>
											</Box>
											}

										</Box>
									))}
								</Box>
							</Box>}
							<Box className="active-chapters"> 
								<Box sx={{paddingY: '1%', fontWeight: 500, fontSize: 18, color: "#343C6A"}}>
									Active Chapters
								</Box>
								<Box display="flex" flexDirection="column" gap="1rem">
									{activeChapters.map((chapter)=>(
										<Box key={chapter.chapterId} width="100%" minWidth="700px" maxWidth="1750px" display="flex" gap="1.5rem" paddingX="2rem" justifyContent="space-around" bgcolor="#FFFFFF"  sx={{ borderRadius: 4}}>
											<Box width="100%" maxWidth="200px" overflow="hidden" paddingY="5px">
											<img 
												style={imageStyle} 
												src={chapter.chapterPhoto !== "" ? chapter.chapterPhoto : "/images/login.png"} 
												alt="Chapter"
											/>

											</Box>
											<Box flexGrow={1} display="flex" flexDirection="column" alignContent="center" justifyContent="space-around">
												<Box>
													<Typography variant="h3"><strong>Name:</strong> {chapter.chapterName}</Typography>
													<Typography variant="h3"><strong>Address:</strong> {chapter.chapterAddress}</Typography>
												</Box>
												<Box>
													<Typography variant="h3"><strong>President:</strong> {chapter.chapterPresident}</Typography>
													<Typography variant="h3">{chapter.email}</Typography>
												</Box>
											</Box>
											{(role === 'chapterPresident'|| role === 'Admin') &&
											<Box display="flex" flexDirection="column" alignContent="center" justifyContent="space-around" >
												<IconButton onClick={(event) => handleClickApproved(event, chapter)} >
													<MoreVertIcon sx={{ fontSize: 60 }}/>
												</IconButton>
												<Menu
													anchorEl={anchorElApproved}
													open={openApproved}
													onClose={handleCloseApproved}
													anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
													transformOrigin={{ vertical: 'top', horizontal: 'right' }}
												>
													<MenuItem onClick={() => {setOpenPopUp(true)}}>Withdraw</MenuItem>
													<WithdrawPopUp openPopUp = {openPopUp} handleClose = {() => setOpenPopUp(false)} handleWithdraw = {() =>{handleWithdraw(chapter.chapterId)}}/>
													<ChapterButton type="edit" handleCloseApproved={handleCloseApproved} chapter={selectedApprovedRow} onEdit={handleEditActive}/>
												</Menu>
											</Box>}
										</Box>
									))}
								</Box>
							</Box>

						</Box>
					</Grid>
				</Grid>


			</Grid>



		</Box>
	);
};

export default ChapterPage;
